import "./LogoAndEaseMenu.css";
import React, {useState, useEffect} from "react";
import DarkModeToggle from './DarkModeToggle.js';
import axios from 'axios';
import { ApiKey, dataUrl} from '../config.js'
import Spinner from './Spinner.js';
import RevisionSchedulerList from "./RevisionSchedulerList.js";





export function HomeButton({OnClickFunction})
{
  return(
    <button type="button" className="EaseMenuHome" onClick={OnClickFunction}> 
                <svg id= "a" version="1.1" xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 547.596 547.596">
                <g>
                  <path fill="currentColor" d="M540.76,254.788L294.506,38.216c-11.475-10.098-30.064-10.098-41.386,0L6.943,254.788
                    c-11.475,10.098-8.415,18.284,6.885,18.284h75.964v221.773c0,12.087,9.945,22.108,22.108,22.108h92.947V371.067
                    c0-12.087,9.945-22.108,22.109-22.108h93.865c12.239,0,22.108,9.792,22.108,22.108v145.886h92.947
                    c12.24,0,22.108-9.945,22.108-22.108v-221.85h75.965C549.021,272.995,552.081,264.886,540.76,254.788z"/>
                </g>
                </svg>    
      </button>
  );
}
export default function LogoAndEaseMenu({ OnClickFunction, onClickEaseMenu, isIconizedMenu})
{
    return (
    <div className={ "AllMenusSidebar"  + (isIconizedMenu ? " SideBarOnIconizedMenu" : "")}>
      <button type="button" className="EaseMenu" onClick={onClickEaseMenu}> 
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M166.154-274.154v-36.923h627.692v36.923H166.154Zm0-187.692v-36.923h627.692v36.923H166.154Zm0-187.692v-36.924h627.692v36.924H166.154Z"/></svg>
      </button>
      <HomeButton OnClickFunction={OnClickFunction} />
      <span className="cclogorotate">C-I-V-I-L-S-C-O-D-E </span>
      </div>);
}

export function EaseMenuDiag({subjects, OnSearchResults, backToLogin, loadContentPage, revisions, onRevisionsUpdate})
{
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    function OnSearch(searchstring)
  {
    if(searchstring.length<=3)
      return
    setIsLoading(true);
    var req = {
      email: localStorage.getItem('user'),
      method: 'SearchWebsite',
      params: {
        SearchString: searchstring
      }
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
      setIsLoading(false);
      OnSearchResults(resp.data, searchstring);
    }).catch(err => {console.log(err);
      if(err.code === "ERR_BAD_REQUEST")
        backToLogin();
      else
       OnSearchResults(null, searchstring);
      
    });
  }

  function onLogout()
  {
    localStorage.clear();
    sessionStorage.clear();
    // API request to Server.
    window.location.reload();
  };


    return (
      <div>
      {isLoading ? <Spinner wholepage={true}/> : 
        <div className="EaseMenuDiag">
                <form className='menu_search' onSubmit={(e) => { e.preventDefault();OnSearch(search); }}>
                <input type="text" placeholder="Search Topic" name="search" class="menu_search--bar" value={search} onChange={(e) => setSearch(e.target.value)}/>
                <button class="menu_search--button" disabled={search.length < 3}>
                                 <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M779.385-153.846 528.923-404.307q-30 25.538-69 39.538-39 14-78.385 14-96.1 0-162.665-66.529-66.566-66.529-66.566-162.577t66.529-162.702q66.529-66.654 162.577-66.654 96.049 0 162.702 66.565Q610.769-676.101 610.769-580q0 41.692-14.769 80.692-14.769 39-38.769 66.693l250.462 250.461-28.308 28.308ZM381.538-390.769q79.616 0 134.423-54.808Q570.769-500.385 570.769-580q0-79.615-54.808-134.423-54.807-54.808-134.423-54.808-79.615 0-134.423 54.808Q192.308-659.615 192.308-580q0 79.615 54.807 134.423 54.808 54.808 134.423 54.808Z"></path></svg>
                </button>
                </form>
            <div className="menu_other">
              <DarkModeToggle />
              <button type="button" class="menu_other--logout" onClick={() => {onLogout();}}>Logout</button>
            </div>
            <RevisionSchedulerList subjects={subjects} backToLogin={backToLogin} loadContentPage={loadContentPage} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>
        </div>
      }
      </div>
    );
}
