import React from 'react'
import SyllabusPage from './SyllabusPage.js';
import ContentPageWithBackButton from './ContentPageWithBackButton.js';
import SearchResultsControl from './SearchResults.js';
import './MainPage.css';
import {useState, useEffect} from "react";


export default function MainPage({backToLogin, subjects, initialpage, revisions, onRevisionsUpdate})
{
  const [saveUserState, setsaveUserState] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevMenuPage, setPrevMenuPage] = useState(null);


  const [page, setPage] = useState(initialpage);
  useEffect(() => {
    setPage(initialpage);

  }, [initialpage])
  // Doesn't work for safari.

  /*const handleScroll = () => {
    setEaseMenu(false);
  };*/
   // Every 10 min we will try to save state.
   useEffect(() => {
    // Doesn't work for safari.
    //window.addEventListener('scroll', handleScroll);
    setPage(initialpage);
 
    const timer = setInterval(() => {
      setsaveUserState(saveUserState => !saveUserState);
    }, 10*60*1000);


    return () => {
      clearInterval(timer)
      //window.removeEventListener('scroll', handleScroll);
    };

   }, [])
   
   

  function cacheCurrentMenuPage()
  {
    const scrollPosition = window.scrollY;
    setScrollPosition(scrollPosition);
    setPrevMenuPage(page);
  }
  function restorePrevMenuPageAndState()
  {
    setPage(prevMenuPage);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
   }, 30);
  }
  function onLoadContentPage(subject, TK, Topic, type, TopicListForNxtAndPrev = null)
  {
    // Only cache menu
    if(page.SubjectPage.loadSubjectPage===true)
      cacheCurrentMenuPage();
    setPage({ 
      SubjectPage : 
        {
          loadSubjectPage: false, 
          selectedSub: subject
        },
        ContentPage : 
        {
          loadContentPage: true, 
          TK: TK, 
          Topic:Topic,
          Type: type,
          TopicListForNxtAndPrev: TopicListForNxtAndPrev
        },
        SearchResultsPage: 
        {
         loadSearchResultsPage: false,
         SearchResults : null
        }
      });
  }


 function onContentPageBackButton()
 {
  restorePrevMenuPageAndState();
 }



  //todo:: props.
  return (
    <div className="FullMainPage" onContextMenu={(e) => e.preventDefault()}>
      <div>
        {page.SearchResultsPage.loadSearchResultsPage && <SearchResultsControl  backToLogin={backToLogin} subjects={subjects} Results={page.SearchResultsPage.SearchResults} saveState={saveUserState} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate} searchstring={page.SearchResultsPage.SearchStringUsed}/>}
        {page.SubjectPage.loadSubjectPage && <div className='MainPage'>
       <SyllabusPage backToLogin={backToLogin} subject={page.SubjectPage.selectedSub} loadContentPage={onLoadContentPage} revisions={revisions.filter(revision => revision.Navigation.startsWith(page.SubjectPage.selectedSub.SubjectName))}/>
      </div>}
      {page.ContentPage &&  page.ContentPage.loadContentPage && <ContentPageWithBackButton key={JSON.stringify(page)} backToLogin={backToLogin} tK={page.ContentPage.TK} topic={page.ContentPage.Topic} type={page.ContentPage.Type} subject={page.SubjectPage.selectedSub} saveState={saveUserState} TopicsList={page.ContentPage.TopicListForNxtAndPrev} LoadContentPageForNextPrev={onLoadContentPage} onBackButton={ prevMenuPage ? onContentPageBackButton : null} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>}
      </div>

    </div>
  )
}